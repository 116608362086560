import React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {Box, Button, Stack, Typography} from "@mui/material";
import packageJson from "../../package.json";
import {useTranslation} from "react-i18next";

function Login() {
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');

    const routeParameter = id !== null ? `?id=${id}` : ""

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            height="100vh"
        >
            <Stack spacing={2} alignItems="center">
                <div
                    style={{
                        borderRadius: "50%",
                        background: "#061ab1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexFlow: "column",
                        height: "80px",
                        width: "80px",
                    }}
                >
                    <img src={"/logo192.png"} width={"65px"} />
                </div>
                <Typography variant={"h4"}>Lost & Found Login</Typography>
                <Button
                    variant="contained"
                    color={"secondary"}
                    size={"large"}
                    fullWidth
                    href={`${packageJson.internalUrl}${routeParameter}`}
                    target="_self"
                    rel="noopener noreferrer"
                >
                    Internal
                </Button>
                <Button
                    variant="contained"
                    color={"primary"}
                    size={"large"}
                    fullWidth
                    href={`${packageJson.externalUrl}${routeParameter}`}
                    target="_self"
                    rel="noopener noreferrer"
                >
                    External
                </Button>
            </Stack>
        </Box>
    );
}

export default Login;
